import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'dash-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  constructor(
    public authService: AuthenticationService,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {}

  logout() {
    this.authService.Logout().then(x => {
      this.popoverController.dismiss();
    });
  }
}
