import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthenticationService } from '../shared/authentication.service';
import { User } from '../shared/User';
import { Router } from '@angular/router';
import { PopoverComponent } from './popover/popover.component';
import {DatePickerComponent} from 'ng2-date-picker';  
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, startWith } from 'rxjs/operators';
import { ClientsService } from '../shared/clients.service';
import { Client } from '../shared/Client';
import { formatDate } from '@angular/common';

@Component({
  selector: 'dash-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  selectedDate: string = new Date().toISOString();
  loggedIn: boolean;
  user: User;
  todaysDate: string;

  constructor(
    public authService: AuthenticationService,
    public popoverController: PopoverController,
    public clientsService: ClientsService,
    private router: Router
  ) {
    this.loggedIn = this.authService.isLoggedIn;
    this.user = this.authService.GetSystemUserData();
  }

  ngOnInit() {
    var today = new Date();

    this.todaysDate = formatDate(today, 'EEEE, MMMM d y', 'en_US');
  }

  async openPopover(event: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'dash-popover',
      event: event,
      translucent: true,
    });
    return await popover.present();
  }

  openMenu() {

  }



}
