import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { AuthenticationService } from "../shared/authentication.service";
import { ClientsService } from "../shared/clients.service";

@Component({
	selector: "dash-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
	id: any;
	mode: any = "light";
	searchResults: any;
	clients: any;
	selectedClient: any;
	adminMenuCollapsed: boolean = false;
	firstLoad: boolean = false;

	public searchField: FormControl;

	constructor(
		private menuController: MenuController,
		private authService: AuthenticationService,
		public clientsService: ClientsService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.searchField = new FormControl("");
		this.searchResults = [];
		this.adminMenuCollapsed = false;
		this.clientsService.getAll().subscribe((collection) => {
			this.clients = collection;
		});
	}

	ngOnInit() {
		this.menuController.open("main");

		this.authService.colorTheme.subscribe((x) => {
			this.mode = x;

			if (this.mode === "light") {
				document.body.classList.add("light-mode");
				document.body.classList.remove("dark-mode");
			} else {
				document.body.classList.add("dark-mode");
				document.body.classList.remove("light-mode");
			}
		});

		this.authService.selectedClient.subscribe((client) => {
			let searchResult = {
				id: client.$id,
				title: client.name,
				url: `/clients/${client.$id}`,
			};

			this.handleSearchResultClick(searchResult);

			this.firstLoad = true;
		});
	}

	handleSearch(event) {
		this.searchResults = [];

		if (this.searchField.value.length > 0) {
			this.clients.forEach((client) => {
				const haystack = client.name.toLowerCase();
				const needle = this.searchField.value.toLowerCase();

				if (haystack.includes(needle)) {
					let searchResult = {
						id: client.$id,
						title: client.name,
						url: `/clients/${client.$id}`,
					};

					this.searchResults.push(searchResult);
				}
			});
		}
	}

	handleSearchResultClick(searchResult) {
		this.searchField.setValue(searchResult.title);
		this.searchResults = [];
		this.selectedClient = searchResult;

		if (this.firstLoad) {
			this.router.navigateByUrl(
				`/clients/${searchResult.id}/monthly-marketing`
			);
		}
	}

	handleSearchClear() {
		this.searchResults = [];
		this.selectedClient = null;
	}

	isActive(path) {
		return this.router.url.indexOf(path) === 0;
	}

	toggleDarkMode() {
		if (this.mode === "light") {
			this.authService.setColorTheme("dark");
		} else {
			this.authService.setColorTheme("light");
		}
	}

	toggleAdminMenu() {
		this.adminMenuCollapsed = !this.adminMenuCollapsed;
	}
}
