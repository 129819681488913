import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedDateComponent } from './selected-date.component';
import {IonicModule} from '@ionic/angular';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
// import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

// the `default as` syntax.
// import * as _moment from 'moment';


// const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'LL',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [
        SelectedDateComponent
    ],
    exports: [
        SelectedDateComponent
    ],
    providers: [
        // {
        //     provide: DateAdapter,
        //     // useClass: MomentDateAdapter,
        //     // deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        //   },
      
        //   {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class SelectedDateModule {}
