import { Injectable, NgZone } from "@angular/core";
import { User } from "./User";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import {
	AngularFirestore,
	AngularFirestoreDocument,
} from "@angular/fire/firestore";
import firebase from "firebase/app";

@Injectable({
	providedIn: "root",
})
export class AuthenticationService {
	user: any;
	profileDoc: AngularFirestoreDocument;
	profile: Observable<any>;
	userData: any;
	auth: any;

	private selectedDateSource = new BehaviorSubject(this.getSelectedDate());
	selectedDate = this.selectedDateSource.asObservable();

	private selectedShowCompleteTasksSource = new BehaviorSubject(
		this.getShowCompletedTasks()
	);
	selectedShowCompleteTasks =
		this.selectedShowCompleteTasksSource.asObservable();

	private colorThemeSource = new BehaviorSubject(this.getColorTheme());
	colorTheme = this.colorThemeSource.asObservable();

	private selectedClientSource = new BehaviorSubject(this.getSelectedClient());
	selectedClient = this.selectedClientSource.asObservable();

	constructor(
		public db: AngularFirestore,
		public ngFireAuth: AngularFireAuth,
		public router: Router,
		public ngZone: NgZone
	) {
		this.user = JSON.parse(localStorage.getItem("user"));
		this.auth = this.ngFireAuth.authState;

		if (this.user && this.user.uid) {
			this.profileDoc = this.db.doc(`/users/${this.user.uid}`);
		}

		this.ngFireAuth.authState.subscribe((user) => {
			if (user) {
				this.userData = user;
				this.profileDoc = this.db.doc(`/users/${user.uid}`);

				this.profileDoc.snapshotChanges();

				this.SetUserData(user);

				localStorage.setItem("user", JSON.stringify(this.userData));
				localStorage.setItem("userProfile", JSON.stringify(this.user));

				JSON.parse(localStorage.getItem("user"));
			} else {
				localStorage.setItem("user", null);
				JSON.parse(localStorage.getItem("user"));
			}
		});
	}

	public getColorTheme() {
		const cached = localStorage.getItem("colorTheme");
		const _default = "light";

		if (!cached) {
			localStorage.setItem("colorTheme", _default);

			return _default;
		} else {
			return cached;
		}
	}

	public setColorTheme(d: string): void {
		this.colorThemeSource.next(d);
		localStorage.setItem("colorTheme", d);
	}

	public getSelectedClient() {
		const cached = localStorage.getItem("selectedClient");
		const _default = "";

		if (!cached) {
			localStorage.setItem("selectedClient", _default);

			return _default;
		} else {
			return JSON.parse(cached);
		}
	}

	public setSelectedClient(doc: object): void {
		localStorage.setItem("selectedClient", JSON.stringify(doc));
	}

	public getSelectedDate() {
		const cached = localStorage.getItem("selectedDate");

		if (!cached) {
			const today = new Date();
			localStorage.setItem("selectedDate", today.toISOString());

			return today;
		} else {
			return new Date(cached);
		}
	}

	public setSelectedDate(d: Date): void {
		// check if d is a date or a string
		this.selectedDateSource.next(d);
		localStorage.setItem("selectedDate", d.toISOString());
	}

	public getShowCompletedTasks() {
		const cached = localStorage.getItem("showCompletedTasks");

		if (!cached) {
			localStorage.setItem("showCompletedTasks", "false");

			return false;
		} else {
			return cached;
		}
	}

	public setShowCompletedTasks(value: boolean): void {
		this.selectedShowCompleteTasksSource.next(value.toString());

		localStorage.setItem("showCompletedTasks", value.toString());
	}

	getUserProfile() {
		return this.profileDoc.valueChanges();
	}

	updateUserProfile(data) {
		this.profileDoc.update(data);
	}

	// Login in with email/password
	SignIn(email, password) {
		return this.ngFireAuth.signInWithEmailAndPassword(email, password);
	}

	// Register user with email/password
	RegisterUser(email, password) {
		return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
	}

	// Email verification when new user register
	SendVerificationMail() {
		// return this.ngFireAuth.currentUser.sendEmailVerification()
		// .then(() => {
		//   this.router.navigate(['verify-email']);
		// })
	}

	// Recover password
	PasswordRecover(passwordResetEmail) {
		return this.ngFireAuth
			.sendPasswordResetEmail(passwordResetEmail)
			.then(() => {
				console.log(
					"Password reset email has been sent, please check your inbox."
				);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	// Returns true when user is looged in
	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem("user"));

		return user;
	}

	// Returns true when user's email is verified
	get isEmailVerified(): boolean {
		const user = JSON.parse(localStorage.getItem("user"));

		return user.emailVerified !== false ? true : false;
	}

	// Sign in with Gmail
	GoogleAuth() {
		return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
	}

	// Auth providers
	AuthLogin(provider) {
		return this.ngFireAuth
			.signInWithPopup(provider)
			.then((result) => {
				this.ngZone.run(() => {
					this.router.navigate(["/blocks/my-tasks"]);
				});

				this.SetUserData(result.user);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	Logout() {
		return this.ngFireAuth
			.signOut()
			.then((result) => {
				this.ngZone.run(() => {
					this.router.navigate(["login"]);
				});
				this.SetUserData({});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	GetUserData() {
		// const userRef  = this.db.doc(`users/${this.auth.uid}`).get();
		// const userRef: AngularFirestoreDocument<any> = this.db.doc(`users/${this.user.uid}`);
		const user = JSON.parse(localStorage.getItem("user"));
		return user;
	}

	GetSystemUserData() {
		const user = JSON.parse(localStorage.getItem("systemUser"));
		return user;
	}

	GetSystemUser(user) {
		let systemUser;
		let systemUserQuery = this.db
			.collection(`/users`, (ref) => {
				return ref.where("email", "==", user.email);
			})
			.get();

		systemUserQuery.forEach((results) => {
			results.forEach((doc) => {
				systemUser = doc;
			});
		});

		return systemUser
	}

	// Store user in localStorage
	SetUserData(user) {
		let systemUserQuery = this.db
			.collection(`/users`, (ref) => {
				return ref.where("email", "==", user.email);
			})
			.get();

		systemUserQuery.forEach((results) => {
			results.forEach((doc) => {
				let data = doc.data();

				if (user && user.photoURL) {
					// @ts-ignore
					// data.profileAvatarURL = user.photoURL;

					localStorage.setItem("systemUser", JSON.stringify(data));
	
					// @ts-ignore
					this.db.doc(`users/${doc.id}`).update(data);
				}
			});
		});
	}

	// Sign-out
	SignOut() {
		return this.ngFireAuth.signOut().then(() => {
			localStorage.removeItem("user");
			this.router.navigate([""]);
		});
	}
}
