import {Component, OnInit} from '@angular/core';

import { Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserDataService } from './shared/user-data.service';
import { AuthenticationService } from './shared/authentication.service';

import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
  loggedIn = false;
  showLoading = true;
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthenticationService,
  ) {
    this.authService.auth.subscribe(data => {
      this.showLoading = true;

      if (data) {
        this.loggedIn = true;

        setTimeout(() => {
          this.showLoading = false;
        }, this.randomIntFromInterval(10, 30));
      } else {
        this.loggedIn = false;
        console.log('logged out')

        setTimeout(() => {
          this.showLoading = false;
        }, this.randomIntFromInterval(10, 30));
      }
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.showLoading = false;
    });
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  async ngOnInit() {
    const loggedIn = this.checkLoginStatus();
  }

  checkLoginStatus() {
    const loggedIn = this.authService.isLoggedIn;

    return this.updateLoggedInStatus(loggedIn);
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      // this.loggedIn = loggedIn;
    }, 300);
  }
}
