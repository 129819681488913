import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import {
	createAnimation,
	IonicModule,
	IonicRouteStrategy,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirestoreModule } from "@angular/fire/firestore";
// import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
// import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/database';
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
// import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';

// environment
import { environment } from "../environments/environment";
import { IonicStorageModule } from "@ionic/storage";
import { AuthenticationService } from "./shared/authentication.service";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { DashTableComponent } from "./dash-table/dash-table.component";
import { SelectedDateComponent } from "./selected-date/selected-date.component";
import { SortPipe } from "./pipes/sort.pipe";
import { SelectedDateModule } from "./selected-date/selected-date.module";
import { MaterialModule } from "./material.module";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { LoadingComponent } from "./shared/loading/loading.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CommonModule } from "@angular/common";

const enterAnimation = (baseEl: any) => {
	const backdropAnimation = createAnimation()
		// tslint:disable-next-line:no-non-null-assertion
		.addElement(baseEl.querySelector("ion-backdrop")!)
		.fromTo("opacity", "0.01", "var(--backdrop-opacity)");

	const wrapperAnimation = createAnimation()
		// tslint:disable-next-line:no-non-null-assertion
		.addElement(baseEl.querySelector(".modal-wrapper")!)
		.fromTo("transform", "translateX(100%)", "translateX(0)")
		.fromTo("opacity", "var(--backdrop-opacity)", 1);

	return createAnimation()
		.addElement(baseEl)
		.easing("ease-in-out")
		.duration(300)
		.addAnimation([wrapperAnimation, backdropAnimation]);
};

const exitAnimation = (baseEl: any) => {
	const backdropAnimation = createAnimation()
		.addElement(baseEl.querySelector("ion-backdrop")!)
		.fromTo("opacity", "var(--backdrop-opacity)", "0");

	const wrapperAnimation = createAnimation()
		.addElement(baseEl.querySelector(".modal-wrapper")!)
		.fromTo("transform", "translateX(0)", "translateX(100%)");

	return createAnimation()
		.addElement(baseEl)
		.easing("ease-in-out")
		.duration(300)
		.addAnimation([wrapperAnimation, backdropAnimation]);
};

// const fadeThrough = (baseEl: any) => {
//   const backdropAnimation = createAnimation()
//     .addElement(baseEl.querySelector('#main')!)
//     .fromTo('opacity', '0.01', '2');

//   return createAnimation()
//     .addElement(baseEl)
//     .easing('ease-in-out')
//     .duration(300)
//     .addAnimation([backdropAnimation]);
// }
@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		SidebarComponent,
		DashTableComponent,
		SortPipe,
		LoadingComponent,
	],
	entryComponents: [],
	imports: [
		BrowserModule,
    CommonModule,
		IonicModule.forRoot({
			mode: "ios",
			// modalEnter: enterAnimation,
			// modalLeave: exitAnimation,
		}),
		ReactiveFormsModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		AngularFireDatabaseModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		IonicStorageModule.forRoot(),
		BrowserAnimationsModule,
		SelectedDateModule,
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000",
		}),
		MaterialModule
	],
	providers: [
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		AuthenticationService,
		AngularFirestoreModule,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
