import { Injectable } from "@angular/core";
import {
	AngularFirestore,
	AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { Client } from "../shared/Client";
import { isString } from "@angular/fire/database/utils";
import { Task } from "./Task";
import firebase from "firebase/app";
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
	providedIn: "root",
})
export class ClientsService {
	clients: Observable<Client[]>;

	private collection: AngularFirestoreCollection<Client>;

	constructor(private firestore: AngularFirestore) {
		this.collection = firestore.collection<Client>("clients", (ref) => {
			return ref;
			// return ref.where('status', '==', 'Active');
		});
		this.clients = this.collection.valueChanges([
			"added",
			"removed",
			"updated",
		]);
	}

	get(id) {
		return this.firestore
			.doc(`/clients/${id}`)
			.valueChanges({ idField: "$id" });
	}

	update(id, data) {
		const payload = { ...data };

		return this.collection.doc(id).update(payload);
	}

	delete(id) {
		return this.collection.doc(id).delete();
	}

	create(data) {
		const defaults = {
			status: "Active",
			overUnder: 0,
		};
		const payload = { ...defaults, ...data };

		return this.collection.add(payload);
	}

	archive(id, data) {
		const payload = { ...data };

		payload.status = "Inactive";

		return this.collection.doc(id).update(payload);
	}

	getAll() {
		return this.collection.valueChanges({ idField: "$id" });
	}
	
	getAllActive() {
		return this.firestore.collection(`/clients`, (ref) => {
			return ref.where("status", "==", "Active");
		})
		.valueChanges({ idField: "$id" });
	}

	// Contracts
	completeContract(clientId: string, contactId: string) {
		return this.firestore
			.collection(`/clients/${clientId}/contracts`)
			.doc(contactId)
			.update({ complete: true });
	}

	getContracts(id: string) {
		return this.firestore
			.collection(`/clients/${id}/contracts`)
			.valueChanges({ idField: "$id" });
	}

	async updateContract(clientId: string, contractId: string, data: any) {
		return this.firestore
			.collection(`/clients/${clientId}/contracts`)
			.doc(contractId)
			.update(data);
	}

	deleteContract(clientId: string, contractId: string) {
		return this.firestore
			.collection(`/clients/${clientId}/contracts`)
			.doc(contractId)
			.delete();
	}

	createContract(clientId: string, data: any) {
		const defaults = {
			projectType: "",
			startDate: this.toFirestoreDate(new Date()),
			endDate: this.toFirestoreDate(new Date()),
			monthlyBlocks: 0,
			blockRate: 0,
		};

		if (data.startDate) {
			data.startDate = this.toFirestoreDate(data.startDate);
		}

		if (data.endDate) {
			data.endDate = this.toFirestoreDate(data.endDate);
		}

		const payload = { ...defaults, ...data };

		return this.firestore
			.collection(`/clients/${clientId}/contracts`)
			.add(payload);
	}

	getCurrentContract(
		id: string,
		date: Date,
		type: string = "Monthly Marketing"
	) {
		const start = new Date(date);
		start.setDate(1);
		start.setHours(0, 0, 0, 0);

		const end = new Date(date);
		end.setDate(31);
		end.setHours(0, 0, 0, 0);

		const doc = this.firestore.doc(`/clients/${id}`);

		// const list = doc.collection('contracts', (ref) => {
		//   // ref.where('endDate', '>=', end).where('startDate', '<=', start)
		//   let temp = ref.where('endDate', '>=', start)

		//   return temp.where('startDate', '<', end)
		// });

		const list = doc.collection("contracts");

		list.get().forEach((collection) => {
			let docs = collection.docs;

			docs.forEach((d) => {
				console.log(d);
			});
		});

		return list.get();
	}

	getCurrentWebsiteBuildContract(id: string) {
		return this.firestore
			.collection(`/clients/${id}/contracts`, (ref) => {
				return ref
				// return ref.where("projectType.name", "==", "Website Build");
			})
			.valueChanges({ idField: "$id" });
	}

	// Blocks
	getBlocks(id: string) {
		return this.firestore
			.collection(`/clients/${id}/blocks`)
			.valueChanges({ idField: "$id" });
	}

	getBlocksByContractId(clientId: string, contractId: string) {
		return this.firestore
			.collection(`/clients/${clientId}/blocks`, (ref) => {
				return ref.where("contract", "==", contractId);
			})
			.get();
	}

	getBlockByDate(clientId: string, date: Date) {
		return this.firestore
			.collection(`/clients/${clientId}/blocks`, (ref) => {
				return ref.where("date", "==", date);
			})
			.get();
	}

	getAllBlocks(id: string) {
		return this.firestore.collection(`/clients/${id}/blocks`).get();
	}

	updateBlock(clientId: string, blockId: string, data: any) {
		return this.firestore
			.collection(`/clients/${clientId}/blocks`)
			.doc(blockId)
			.update(data);
	}

	deleteBlock(clientId: string, blockId: string) {
		return this.firestore
			.collection(`/clients/${clientId}/blocks`)
			.doc(blockId)
			.delete();
	}

	createBlock(clientId: string, data: any) {
		if (data.date && typeof data.date === "string") {
			data.date = new Date(data.date);
		}

		if (data.date) {
			data.date.setDate(1);
			data.date.setHours(0, 0, 0, 0);
		}

		const defaults = {
			billed: 0,
			completed: 0,
			contracted: 0,
			description: "",
			scheduled: 0,
			planned: 0,
		};
		const payload = { ...defaults, ...data };

		return this.firestore
			.collection(`/clients/${clientId}/blocks`)
			.add(payload);
	}

	getCurrentBlock(id: string, date: Date) {
		const start = new Date(date);
		start.setDate(1);
		start.setHours(0, 0, 0, 0);

		const end = new Date(date);
		end.setDate(27);
		end.setHours(0, 0, 0, 0);

		const doc = this.firestore.doc(`/clients/${id}`);
		// const blocks = doc.collection('blocks', ref => ref.where('date', '>=', start).where('date', '<=', end));

		const list = doc.collection("blocks", (ref) => {
			return ref.where("date", ">=", start).where("date", "<", end);
		});

		return list.valueChanges({ idField: "$id" });
	}

	getBlockByContract(clientId: string, contractId: string) {
		const doc = this.firestore.doc(`/clients/${clientId}`);
		const list = doc.collection("blocks", (ref) => {
			return ref.where("contract", "==", contractId)
		});

		return list.valueChanges({ idField: "$id" });
	}

	// Contacts
	getContacts(id: string) {
		return this.firestore
			.collection(`/clients/${id}/contacts`)
			.valueChanges({ idField: "$id" });
	}

	updateContact(clientId: string, contactId: string, data: any) {
		return this.firestore
			.collection(`/clients/${clientId}/contracts`)
			.doc(contactId)
			.update(data);
	}

	deleteContact(clientId: string, contactId: string) {
		return this.firestore
			.collection(`/clients/${clientId}/contracts`)
			.doc(contactId)
			.delete();
	}

	createContact(clientId: string, data: any) {
		const defaults = {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			ext: "",
			jobTitle: "",
			isPrimary: false,
		};
		const payload = { ...defaults, ...data };

		return this.firestore
			.collection(`/clients/${clientId}/contacts`)
			.add(payload);
	}

	monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	toFirestoreDate(date: any) {
		return Timestamp.fromDate(new Date(date));
	}
}
