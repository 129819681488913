import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'dash-selected-date',
  templateUrl: './selected-date.component.html',
  styleUrls: ['./selected-date.component.scss'],
})
export class SelectedDateComponent implements OnInit {
  @Input() format = 'MMMM YYYY';
  @Input() yearOnly:boolean = false;

  selectedDate: any;
  selectedDateDisplay: any;
  monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

  constructor(
    public authService: AuthenticationService,
  ) {
    this.authService.selectedDate.subscribe(x => {
      this.selectedDate = x;
      this.selectedDateDisplay = this.monthNames[x.getMonth()] + ' ' + x.getFullYear();
    });
  }

  ngOnInit() {
    console.log(this);
  }

  setSelectedDate(event) {
    const date = event.value;

    if (date) {
      this.authService.setSelectedDate(date);
      this.selectedDateDisplay = this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
    }
  }

  goToPreviousYear() {
    const date = this.selectedDate;
    
    date.setFullYear( this.selectedDate.getFullYear() - 1 );

    this.authService.setSelectedDate(date);

    this.selectedDate = date;
  }

  goToNextYear() {
    const date = this.selectedDate;
    
    date.setFullYear( this.selectedDate.getFullYear() + 1 );

    this.authService.setSelectedDate(date);

    this.selectedDate = date;
  }

  goToPreviousMonth() {
    const date = this.selectedDate;
    
    date.setMonth( this.selectedDate.getMonth() - 1 );

    this.authService.setSelectedDate(date);

    this.selectedDate = date;
    this.selectedDateDisplay = this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
  }

  goToNextMonth() {
    const date = this.selectedDate;
    
    date.setMonth( this.selectedDate.getMonth() + 1 );

    this.authService.setSelectedDate(date);

    this.selectedDate = date;
    this.selectedDateDisplay = this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
  }

  openPicker(dp) {
    dp.open();
  }
}
