import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
	AngularFireAuthGuard,
	canActivate,
	redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";
import { AppComponent } from "./app.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);

const routes: Routes = [
	{
		path: "",
		...canActivate(redirectUnauthorizedToLogin),
		component: AppComponent,
	},

	{
		path: "blocks",
		...canActivate(redirectUnauthorizedToLogin),
		loadChildren: () =>
			import("./blocks/blocks.module").then((m) => m.BlocksPageModule),
	},

	{
		path: "clients",
		...canActivate(redirectUnauthorizedToLogin),
		loadChildren: () => {
			return import("./clients/clients.module").then(
				(m) => m.ClientsPageModule
			);
		},
	},

	{
		path: "admin",
		...canActivate(redirectUnauthorizedToLogin),
		loadChildren: () =>
			import("./admin/admin.module").then((m) => m.AdminPageModule),
	},

	{
		path: "login",
		loadChildren: () =>
			import("./login/login.module").then((m) => m.LoginPageModule),
	},

	// { path: '**', redirectTo: '' },

	{
		path: "create-block",
		canActivate: [AngularFireAuthGuard],
		loadChildren: () =>
			import("./create-blocks/create-blocks.module").then(
				(m) => m.CreateBlocksPageModule
			),
	},
	{
		path: "edit-block",
		loadChildren: () =>
			import("./edit-block/edit-block.module").then(
				(m) => m.EditBlockPageModule
			),
	},
	{
		path: "edit-client",
		loadChildren: () =>
			import("./forms/edit-client/edit-client.module").then(
				(m) => m.EditClientPageModule
			),
	},
	{
		path: "edit-team-member",
		loadChildren: () =>
			import("./forms/edit-team-member/edit-team-member.module").then(
				(m) => m.EditTeamMemberPageModule
			),
	},
	{
		path: "new-team-member",
		loadChildren: () =>
			import("./forms/new-team-member/new-team-member.module").then(
				(m) => m.NewTeamMemberPageModule
			),
	},
	{
		path: "edit-department",
		loadChildren: () =>
			import("./forms/edit-department/edit-department.module").then(
				(m) => m.EditDepartmentPageModule
			),
	},
	{
		path: "new-department",
		loadChildren: () =>
			import("./forms/new-department/new-department.module").then(
				(m) => m.NewDepartmentPageModule
			),
	},
	{
		path: "new-task-type",
		loadChildren: () =>
			import("./forms/new-task-type/new-task-type.module").then(
				(m) => m.NewTaskTypePageModule
			),
	},
	{
		path: "edit-task-type",
		loadChildren: () =>
			import("./forms/edit-task-type/edit-task-type.module").then(
				(m) => m.EditTaskTypePageModule
			),
	},
	{
		path: "new-client",
		loadChildren: () =>
			import("./forms/new-client/new-client.module").then(
				(m) => m.NewClientPageModule
			),
	},
	{
		path: "team",
		loadChildren: () =>
			import("./team/team.module").then((m) => m.TeamPageModule),
	},
	{
		path: "edit-block",
		loadChildren: () =>
			import("./forms/edit-block/edit-block.module").then(
				(m) => m.EditBlockPageModule
			),
	},
	// {
	//   path: 'new-task',
	//   loadChildren: () => import('./forms/new-task/new-task.module').then( m => m.NewTaskPageModule)
	// },
	{
		path: "edit-project-type",
		loadChildren: () =>
			import("./forms/edit-project-type/edit-project-type.module").then(
				(m) => m.EditProjectTypePageModule
			),
	},
	{
		path: "new-project-type",
		loadChildren: () =>
			import("./forms/new-project-type/new-project-type.module").then(
				(m) => m.NewProjectTypePageModule
			),
	},
	{
		path: "new-task",
		loadChildren: () =>
			import("./forms/new-task/new-task.module").then(
				(m) => m.NewTaskPageModule
			),
	},
	{
		path: "edit-task",
		loadChildren: () =>
			import("./forms/edit-task/edit-task.module").then(
				(m) => m.EditTaskPageModule
			),
	},
	{
		path: "blocks",
		loadChildren: () =>
			import("./blocks/blocks.module").then((m) => m.BlocksPageModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
